import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

const RewardLineItem = ({ item }) => {
  let { path, url } = useRouteMatch();
  let { season_id } = useParams();

  return (
    <Link
      to={`/seasons/${season_id}/${item.sku}`}
      style={{ textDecoration: "none" }}
    >
      <ListItem button component="a">
        <ListItemAvatar>
          <Avatar src={item.iconUrl} />
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.description} />
      </ListItem>
    </Link>
  );
};

function SeasonItem() {
  let { season_id } = useParams();
  let { path, url } = useRouteMatch();
  const [loyaltyProgram, setLoyaltyProgram] = React.useState("");
  const [catalogItems, setCatalogItems] = React.useState([]);

  const [name, setName] = React.useState("");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());

  const [detailsPageUrl, setDetailsPageUrl] = React.useState("");

  const [editMode, setEditMode] = React.useState(false);

  const history = useHistory();

  const toggleEditing = () => {
    setEditMode(!editMode);
  };

  React.useEffect(() => {
    const fetchSeasonItem = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
        setLoyaltyProgram(loyaltyProgram);

        let query = `query query {
          getLoyaltySeasonDev(program_id: "${loyaltyProgram}" season_id: "${season_id}") {
            name
            startTime
            endTime
            detailsPageUrl
          }
        }`;

        let result = await API.graphql(graphqlOperation(query));
        let season = result.data.getLoyaltySeasonDev;
        setName(season.name);
        setStartTime(new Date(season.startTime));
        setEndTime(new Date(season.endTime));
        setDetailsPageUrl(season.detailsPageUrl);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSeasonItem();
    fetchSeasonRewardsCatalog();
  }, [season_id]);

  const fetchSeasonRewardsCatalog = async () => {
    try {
      let nextToken = "";

      let query = `query query {
      listRewardsCatalogDevs(program_id: "${season_id}", nextToken: "${nextToken}", limit: 10) {
        items {
          terms
          sku
          reward
          program_id
          name
          internalInstructions
          imageUrl
          iconUrl
          description
          active
        }
        nextToken
      }
    }  
    `;

      let result = await API.graphql(graphqlOperation(query));
      let items = result.data.listRewardsCatalogDevs.items;
      nextToken = result.data.listRewardsCatalogDevs.nextToken;

      while (nextToken != null) {
        query = `query query {
          listRewardsCatalogDevs(program_id: "${season_id}", nextToken: "${nextToken}", limit: 10) {
            items {
              terms
              sku
              reward
              program_id
              name
              internalInstructions
              imageUrl
              iconUrl
              description
              active
            }
            nextToken
          }
        }  
        `;
        result = await API.graphql(graphqlOperation(query));
        items = items.concat(result.data.listRewardsCatalogDevs.items);
        nextToken = result.data.listRewardsCatalogDevs.nextToken;
      }
      items = items.sort((a, b) => {
        let reward_a = JSON.parse(a.reward);
        let reward_b = JSON.parse(b.reward);
        if (reward_a.type == "Threshold" && reward_b.type == "Threshold") {
          if (reward_a.threshold < reward_b.threshold) return -1;
          if (reward_a.threshold > reward_b.threshold) return 1;
        }

        if (reward_a.type == "Recurring" && reward_b.type == "Recurring") {
          if (reward_a.interval < reward_b.interval) return -1;
          if (reward_a.interval > reward_b.interval) return 1;
        }
        return 0;
      });
      setCatalogItems(items);
    } catch (err) {
      console.log(err);
    }
  };

  const remove = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        deleteLoyaltySeasonDev(input: {
          program_id: "${loyaltyProgram}",
          season_id: "${season_id}"}) {
            season_id
        }
      }`;

      console.log(mutation);

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);

      history.push("/seasons");
    } catch (err) {
      console.log(err);
    }
  };

  const save = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        updateLoyaltySeasonDev(input: {
          program_id: "${loyaltyProgram}",
          season_id: "${season_id}",
          name: "${name}",
          startTime: ${startTime.getTime()},
          endTime: ${endTime.getTime()},
          detailsPageUrl: "${detailsPageUrl}"}) {
            season_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <List component="li">
          <ListItem>
            <ListItemText primary="Season ID" secondary={season_id} />
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                label="Season Name"
              />
            ) : (
              <ListItemText primary="Season Name" secondary={name} />
            )}
          </ListItem>
          <ListItem>
            {editMode ? (
              <DateTimePicker
                value={startTime}
                onChange={setStartTime}
                className={classes.shortTextField}
                labelFunc={(date) => date.toLocaleString()}
              />
            ) : (
              <ListItemText
                primary="Start Time"
                secondary={startTime.toLocaleString()}
              />
            )}
          </ListItem>
          <ListItem>
            {editMode ? (
              <DateTimePicker
                value={endTime}
                onChange={setEndTime}
                className={classes.shortTextField}
                labelFunc={(date) => date.toLocaleString()}
                disablePast
              />
            ) : (
              <ListItemText
                primary="End Time"
                secondary={endTime.toLocaleString()}
              />
            )}
          </ListItem>
          <ListItem>
            <Uploader
              fileName={`${loyaltyProgram}_${season_id}_details_image`}
              placeholderFileUrl={detailsPageUrl}
              width={360}
              height="auto"
              onUploadComplete={(url) => {
                setDetailsPageUrl(url);
              }}
              disabled={!editMode}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}>
        {editMode ? (
          <Button startIcon={<Save />} onClick={save}>
            Save Changes
          </Button>
        ) : (
          <Button startIcon={<Edit />} onClick={toggleEditing}>
            Edit
          </Button>
        )}
        <Button startIcon={<Delete />} onClick={remove}>
          Delete Season
        </Button>
      </CardActions>
      <CardContent>
        <Typography variant="title" component="h3">
          Season Rewards List
        </Typography>
        <List component="li" className={classes.root}>
          {catalogItems.map((item) => (
            <RewardLineItem item={item} />
          ))}
          <Link
            to={`/seasons/${season_id}/create`}
            style={{ textDecoration: "none" }}
          >
            <ListItem button component="a" href={`${path}/create`}>
              <ListItemText className={classes.createNewItem}>
                <AddCircle />
                <Typography>Create New Reward</Typography>
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </CardContent>
    </Card>
  );
}

export default SeasonItem;
