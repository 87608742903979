import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import CreateSeason from "../component/CreateSeason";
import CreateSeasonRewardItem from "../component/CreateSeasonRewardItem";
import SeasonItem from "../component/SeasonItem";
import SeasonRewardItem from "../component/SeasonRewardItem";
import Uploader from "../component/Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

const save = () => {};

const SeasonLineItem = ({ season }) => {
  let { path, url } = useRouteMatch();

  return (
    <Link to={`${path}/${season.season_id}`} style={{ textDecoration: "none" }}>
      <ListItem button component="a">
        <ListItemText primary={season.season_id} secondary={season.name} />
      </ListItem>
    </Link>
  );
};

const SeasonsList = () => {
  let { path, url } = useRouteMatch();

  const classes = useStyles();

  const [activeSeason, setActiveSeason] = React.useState("");
  const [seasons, setSeasons] = React.useState([]);
  const [programId, setProgramId] = React.useState();

  const fetchActiveSeason = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
      setProgramId(loyaltyProgram);
      let nextToken = "";

      let query = `query query {
        getLoyaltyProgramDev(program_id: "${loyaltyProgram}") {
          season
        }
      }  
    `;

      let result = await API.graphql(graphqlOperation(query));
      let season = result.data.getLoyaltyProgramDev.season;

      setActiveSeason(season);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSeasons = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
      setProgramId(loyaltyProgram);
      let nextToken = "";

      let query = `query query {
        listLoyaltySeasonDevs(program_id: "${loyaltyProgram}", nextToken: "${nextToken}") {
          items {
            detailsPageUrl
            endTime
            name
            program_id
            season_id
            startTime
          }
          nextToken
        }
      }  
    `;

      let result = await API.graphql(graphqlOperation(query));
      let items = result.data.listLoyaltySeasonDevs.items;
      nextToken = result.data.listLoyaltySeasonDevs.nextToken;

      while (nextToken != null) {
        result = await API.graphql(graphqlOperation(query));
        items = items.concat(result.data.listLoyaltySeasonDevs.items);
      }
      console.log(items);
      setSeasons(items);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSeasonChange = async (event) => {
    try {
      let targetSeason = event.target.value;
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        updateLoyaltyProgramDev(input: {
          program_id: "${loyaltyProgram}",
          season: "${targetSeason}"}) {
          program_id
        }
      }`;

      await API.graphql(graphqlOperation(mutation));
      setActiveSeason(targetSeason);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchActiveSeason();
    fetchSeasons();
  }, []);

  return (
    <Container maxWidth="xl">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="title" component="h3">
            Current Active Season
          </Typography>
          <ListItem>
            <Select
              id="selectSeason"
              value={activeSeason ? activeSeason : "None"}
              onChange={handleSeasonChange}
            >
              {seasons.map((item) => (
                <MenuItem value={item.season_id}>{item.name}</MenuItem>
              ))}
              <MenuItem value={null}>None</MenuItem>
            </Select>
          </ListItem>
          <Typography variant="title" component="h3">
            Seasons
          </Typography>
          <List component="li" className={classes.root}>
            {seasons.map((item) => (
              <SeasonLineItem
                season={item}
                key={item.season_id}
                programId={programId}
              />
            ))}
            <Link to={`${path}/create`} style={{ textDecoration: "none" }}>
              <ListItem button component="a" href={`${path}/create`}>
                <ListItemText className={classes.createNewItem}>
                  <AddCircle />
                  <Typography>Create New Season</Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

function Seasons() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path} component={SeasonsList} />
        <Route exact path={`/seasons/create`} component={CreateSeason} />
        <Route exact path={`/seasons/:season_id`} component={SeasonItem} />
        <Route
          exact
          path={`/seasons/:season_id/create`}
          component={CreateSeasonRewardItem}
        />
        <Route path={`/seasons/:season_id/:sku`} component={SeasonRewardItem} />
      </Switch>
    </Router>
  );
}

export function Header() {
  return "Season Manager";
}

export default Seasons;
