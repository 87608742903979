import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { API, graphqlOperation } from "aws-amplify";
import Base64 from "Base64";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

function AccountItem() {
  let { account_id } = useParams();

  const [email, setEmail] = React.useState("");
  const [loyaltyBalance, setLoyaltyBalance] = React.useState("");
  const [awardValue, setAwardValue] = React.useState(0);

  const award = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      let timestamp = new Date().getTime();
      console.log(user);

      let transactionData = {
        admin: user.username,
        user_id: account_id,
        timestamp: timestamp,
      };

      let transactionId = Base64.btoa(JSON.stringify(transactionData));

      let mutation = `mutation MyMutation {
        createLoyaltyBalanceTransactionsDev(input: {amount: ${awardValue}, description: "Balance adjustment", source: "${user.username}", status: "CREATED", timestamp: ${timestamp}, transaction_id: "${transactionId}", type: "CREDIT", user_id: "${account_id}"}) {
          transaction_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      setAwardValue(0);
      fetchAccountItem();
    } catch (err) {
      console.log(err);
    }
  };

  const makeAdmin = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let idToken = user.signInUserSession.idToken.jwtToken;
      let result = await API.post("restaurantAppRestAPI", "/addadmin", {
        headers: {
          Authorization: idToken,
        },
        body: {
          user_id: account_id,
        },
      });
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAccountItem = async () => {
    try {
      let query = `query query {
        getLoyaltyUsersDev(id: "${account_id}") {
          email
          loyaltyBalance
        }
      }`;

      let result = await API.graphql(graphqlOperation(query));
      let user = result.data.getLoyaltyUsersDev;
      setEmail(user.email);
      setLoyaltyBalance(user.loyaltyBalance);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchAccountItem();
  }, [account_id]);

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <List component="li">
          <ListItem>
            <ListItemText primary="Account ID" secondary={account_id} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Account Email" secondary={email} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Current Loyalty Balance"
              secondary={loyaltyBalance}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.shortTextField}
              value={awardValue}
              onChange={(event) => {
                setAwardValue(Number(event.target.value));
              }}
              label="Value"
            />
            <Button onClick={award}>Award Balance</Button>
          </ListItem>
          <ListItem>
            <Button onClick={makeAdmin}>Make Admin</Button>
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}></CardActions>
    </Card>
  );
}

export default AccountItem;
