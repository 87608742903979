import Auth from "@aws-amplify/auth";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Edit, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
  })
);

function CreateSeasonRewardItem() {
  let { season_id } = useParams();
  const [sku] = React.useState(uuidv4());
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [reward, setReward] = React.useState();
  const [rewardType, setRewardType] = React.useState();
  const [rewardRuleValue, setRewardRuleValue] = React.useState("");

  const [terms, setTerms] = React.useState("");
  const [internalInstructions, setInternalInstructions] = React.useState("");

  const [imageUrl, setImageUrl] = React.useState("");
  const [iconUrl, setIconUrl] = React.useState("");

  const history = useHistory();

  const create = async () => {
    try {
      let rewardsJSON = {
        type: rewardType,
      };
      switch (rewardType) {
        case "Recurring":
          rewardsJSON["interval"] = rewardRuleValue;
          break;
        case "Threshold":
          rewardsJSON["threshold"] = rewardRuleValue;
          break;
      }

      let mutation = `mutation mutation {
        createRewardsCatalogDev(input: {
          program_id: "${season_id}",
          sku: "${sku}",
          active: true,
          reward: ${JSON.stringify(JSON.stringify(rewardsJSON))}
          name: "${name}",
          description: "${description}",
          iconUrl: "${iconUrl}",
          imageUrl: "${imageUrl}",
          internalInstructions: "${internalInstructions}",
          terms: "${terms}"}) {
            sku
        }
      }`;
      let result = await API.graphql(graphqlOperation(mutation));
      history.push(`/seasons/${season_id}/${sku}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTypeChange = (event) => {
    setRewardType(event.target.value);
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Typography variant="title" component="h1">
          Create Season Reward Item
        </Typography>
        <List component="li">
          <ListItem>
            <ListItemText primary="Season ID" secondary={season_id} />
          </ListItem>
          <ListItem>
            <ListItemText primary="SKU" secondary={sku} />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              label="Reward Name"
              helperText="Visible to customer"
              required
              error={!name}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              label="Reward Description"
              helperText="Visible to customer"
              required
              error={!description}
            />
          </ListItem>

          <ListItem>
            <Typography component="p">Reward Type:</Typography>
            <Grid>
              <Radio
                checked={rewardType === "Recurring"}
                onChange={handleTypeChange}
                value="Recurring"
              />
              <Typography component="span">Recurring</Typography>
            </Grid>
            <Grid>
              <Radio
                checked={rewardType === "Threshold"}
                onChange={handleTypeChange}
                value="Threshold"
              />
              <Typography component="span">Threshold</Typography>
            </Grid>
          </ListItem>
          <ListItem>
            <TextField
              className={classes.shortTextField}
              value={rewardRuleValue}
              onChange={(event) => {
                setRewardRuleValue(Number(event.target.value));
              }}
              label="Value"
              required
              error={isNaN(rewardRuleValue) || rewardRuleValue <= 0}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reward Image"
              secondary="Image of the reward. 1024 x 500 px, jpg or png."
            />
          </ListItem>
          <Uploader
            fileName={`${season_id}_reward_${sku}_image`}
            placeholderFileUrl={imageUrl}
            width={512}
            height={250}
            onUploadComplete={(url) => {
              setImageUrl(url);
            }}
          />
          <ListItem>
            <ListItemText
              primary="Reward Icon"
              secondary="Icon of the reward. 256 x 256 px, jpg or png."
            />
          </ListItem>
          <Uploader
            fileName={`${season_id}_reward_${sku}_icon`}
            placeholderFileUrl={iconUrl}
            width={48}
            height={48}
            onUploadComplete={(url) => {
              setIconUrl(url);
            }}
          />
          <ListItem>
            <TextField
              className={classes.textField}
              multiline
              value={terms}
              onChange={(event) => {
                setTerms(event.target.value);
              }}
              label="Terms and Conditions"
              helperText="Visible to customer"
              required
              error={!terms}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              multiline
              value={internalInstructions}
              onChange={(event) => {
                setInternalInstructions(event.target.value);
              }}
              label="Internal instructions to staff"
              helperText="Not visible to customer"
              required
              error={!internalInstructions}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}>
        <Button onClick={create}>Create Season Reward</Button>
      </CardActions>
    </Card>
  );
}

export default CreateSeasonRewardItem;
