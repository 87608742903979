import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import Accounts, { Header as AccountsHeader } from "./screens/Accounts";
import Dashboard, { Header as DashboardHeader } from "./screens/Dashboard";
import Loyalty, { Header as LoyaltyHeader } from "./screens/Loyalty";
import Menu, { Header as MenuHeader } from "./screens/Menu";
import Organizations, {
  Header as OrganizationsHeader,
} from "./screens/Organizations";
import Rewards, { Header as RewardsHeader } from "./screens/Rewards";
import Seasons, { Header as SeasonsHeader } from "./screens/Seasons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const signOut = async () => {
  await Auth.signOut();
};

export default function AuthenticatedApp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/loyalty" />
                </Route>
                {/* <Route path="/dashboard" component={DashboardHeader} /> */}
                <Route path="/loyalty" component={LoyaltyHeader} />
                <Route path="/rewards" component={RewardsHeader} />
                <Route path="/seasons" component={SeasonsHeader} />
                <Route path="/organiations" component={OrganizationsHeader} />
                <Route path="/accounts" component={AccountsHeader} />
              </Switch>
            </Router>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button component="a" href="/loyalty">
            <ListItemText primary={"Loyalty"} />
          </ListItem>
          <ListItem button component="a" href="/rewards">
            <ListItemText primary={"Rewards"} />
          </ListItem>
          <ListItem button component="a" href="/seasons">
            <ListItemText primary={"Seasons"} />
          </ListItem>
          <ListItem button component="a" href="/organizations">
            <ListItemText primary={"Organizations"} />
          </ListItem>
          <ListItem button component="a" href="/accounts">
            <ListItemText primary={"Accounts"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={signOut}>
            <ListItemIcon>{/* <MailIcon /> */}</ListItemIcon>
            <ListItemText primary={"Sign Out"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/menu" component={Menu} />
            <Route path="/loyalty" component={Loyalty} />
            <Route path="/rewards" component={Rewards} />
            <Route path="/seasons" component={Seasons} />
            <Route path="/organizations" component={Organizations} />
            <Route path="/accounts" component={Accounts} />
          </Switch>
        </Router>
      </main>
    </div>
  );
}
