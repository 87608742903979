import Auth from "@aws-amplify/auth";
import { Button, Card, CardActions, CardContent, Grid, List, ListItem, ListItemText, Radio, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Edit, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
  })
);

function CreateOrganization() {
  const [organizationId, setOrganizationId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [internalInstructions, setInternalInstructions] = React.useState("");
  const [coverImageUrl, setCoverImageUrl] = React.useState("");
  const [terms, setTerms] = React.useState("");

  const [loyaltyProgram, setLoyaltyProgram] = React.useState("");

  const history = useHistory();

  const create = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        createOrganizationDev(input: {
          program_id: "${loyaltyProgram}",
          organization_id: "${organizationId}",
          name: "${name}",
          description: "${description}",
          domain: "${domain}",
          terms: "${terms}",
          internalInstructions: "${internalInstructions}",
          coverImageUrl: "${coverImageUrl}"}) {
            organization_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      history.push("/organizations");
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <List component="li">
          <ListItem>
            <TextField
              className={classes.textField}
              value={organizationId}
              onChange={(event) => {
                setOrganizationId(event.target.value);
              }}
              label="Organization ID"
              helperText="ID of the organization, used as sign up code for members of organization"
              required
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              label="Organization Name"
              required
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Organization Logo Banner" />
          </ListItem>

          <ListItem>
            <Uploader
              fileName={
                organizationId
                  ? `${loyaltyProgram}_${organizationId}_cover_image`
                  : uuidv4()
              }
              placeholderFileUrl={coverImageUrl}
              width={300}
              height={100}
              onUploadComplete={(url) => {
                setCoverImageUrl(url);
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={description}
              multiline
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              label="Benefits Description"
              helperText="Short description of benefits for member of organization"
              required
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={domain}
              onChange={(event) => {
                setDomain(event.target.value);
              }}
              label="Email Verification Domain"
              helperText="Member of organizations will only be verified if their email matches this domain"
              required
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={internalInstructions}
              multiline
              onChange={(event) => {
                setInternalInstructions(event.target.value);
              }}
              label="Internal Instructions"
              helperText="Instructions for staff when processing this organization's benefits"
              required
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={terms}
              multiline
              onChange={(event) => {
                setTerms(event.target.value);
              }}
              label="Terms and Conditions"
              helperText="Terms and conditions of benefits shown to member of organization"
              required
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}>
        <Button onClick={create}>Create Organization</Button>
      </CardActions>
    </Card>
  );
}

export default CreateOrganization;
