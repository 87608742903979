import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import CreateOrganization from "../component/CreateOrganization";
import OrganizationItem from "../component/OrganizationItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);
const OrganizationLineItem = ({ organization }) => {
  return (
    <Link
      to={`/organizations/${organization.organization_id}`}
      style={{ textDecoration: "none" }}
    >
      <ListItem button component="a">
        <ListItemText primary={organization.name} />
      </ListItem>
    </Link>
  );
};

const OrganizationsList = () => {
  let { path, url } = useRouteMatch();

  const classes = useStyles();

  const [organizations, setOrganizations] = React.useState([]);
  const [programId, setProgramId] = React.useState();

  const fetchOrganizations = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
      setProgramId(loyaltyProgram);
      let nextToken = "";

      let query = `query query {
        listOrganizationDevs(program_id: "${loyaltyProgram}", nextToken: "${nextToken}") {
          items {
            program_id
            organization_id
            name
          }
        }
      }        
    `;

      let result = await API.graphql(graphqlOperation(query));
      let items = result.data.listOrganizationDevs.items;
      nextToken = result.data.listOrganizationDevs.nextToken;

      while (nextToken != null) {
        result = await API.graphql(graphqlOperation(query));
        items = items.concat(result.data.listOrganizationDevs.items);
      }
      setOrganizations(items);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <Container maxWidth="xl">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="title" component="h3">
            Organizations
          </Typography>
          <List component="li" className={classes.root}>
            {organizations.map((item) => (
              <OrganizationLineItem
                organization={item}
                key={item.organization_id}
              />
            ))}
            <Link to={`${path}/create`} style={{ textDecoration: "none" }}>
              <ListItem button component="a" href={`${path}/create`}>
                <ListItemText className={classes.createNewItem}>
                  <AddCircle />
                  <Typography>Create New Organization</Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

function Organizations() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path} component={OrganizationsList} />
        <Route
          exact
          path={`/organizations/create`}
          component={CreateOrganization}
        />
        <Route
          exact
          path={`/organizations/:organization_id`}
          component={OrganizationItem}
        />
      </Switch>
    </Router>
  );
}

export function Header() {
  return "Organizations Manager";
}

export default Organizations;
