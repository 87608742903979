import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import AccountItem from "../component/AccountItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

const AccountLineItem = ({ account }) => {
  return (
    <Link to={`/accounts/${account.id}`} style={{ textDecoration: "none" }}>
      <ListItem button component="a">
        <ListItemText primary={account.id} secondary={account.email} />
      </ListItem>
    </Link>
  );
};

function AccountsList() {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const search = async () => {
    let nextToken = "";

    let query = `query query {
      searchLoyaltyUsersByEmail(email: "${email}") {
        items {
          id
          email
        }
      }
    }  
  `;

    let result = await API.graphql(graphqlOperation(query));
    let items = result.data.searchLoyaltyUsersByEmail.items;

    setSearchResults(items);
  };

  return (
    <Container maxWidth="xl">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="title" component="h3">
            Account Search
          </Typography>
          <TextField
            className={classes.textField}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            label="Email"
          />
          <Button onClick={search}>Search</Button>

          <List component="li" className={classes.root}>
            {searchResults.map((item) => (
              <AccountLineItem account={item} key={item.id} />
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
}

function Accounts() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path} component={AccountsList} />
        <Route exact path={`/accounts/:account_id`} component={AccountItem} />
      </Switch>
    </Router>
  );
}

export function Header() {
  return "Accounts";
}

export default Accounts;
