import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
  })
);

function CreateSeason() {
  const [seasonId] = React.useState(uuidv4());
  const [name, setName] = React.useState("");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());

  const [detailsPageUrl, setDetailsPageUrl] = React.useState("");

  const history = useHistory();

  const create = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        createLoyaltySeasonDev(input: {
          program_id: "${loyaltyProgram}",
          season_id: "${seasonId}",
          name: "${name}",
          startTime: ${startTime.getTime()},
          endTime: ${endTime.getTime()},
          detailsPageUrl: "${detailsPageUrl}"}) {
            season_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      history.push("/seasons");
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <List component="li">
          <ListItem>
            <ListItemText primary="Season ID" secondary={seasonId} />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.textField}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              label="Season Name"
            />
          </ListItem>
          <ListItem>
            <DateTimePicker
              value={startTime}
              onChange={setStartTime}
              className={classes.shortTextField}
              labelFunc={(date) => date.toLocaleString()}
            />
          </ListItem>
          <ListItem>
            <DateTimePicker
              value={endTime}
              onChange={setEndTime}
              className={classes.shortTextField}
              labelFunc={(date) => date.toLocaleString()}
              disablePast
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Season Details Image" />
          </ListItem>
          <ListItem>
            <Uploader
              fileName={`${seasonId}_details_image`}
              placeholderFileUrl={detailsPageUrl}
              width={360}
              height="auto"
              onUploadComplete={(url) => {
                setDetailsPageUrl(url);
              }}
            />
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}>
        <Button onClick={create}>Create Season</Button>
      </CardActions>
    </Card>
  );
}

export default CreateSeason;
