import { CircularProgress, Container, Fab, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { Storage } from "aws-amplify";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ColorPicker, createColor } from "material-ui-color";
import React from "react";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

const Uploader = ({
  fileName,
  placeholderFileUrl,
  width,
  height,
  onUploadComplete,
  disabled,
}) => {
  const [uploadStarted, setUploadStarted] = React.useState(false);
  const [uploadedFileUrl, setUploadedFileUrl] = React.useState();
  const [progress, setProgress] = React.useState(0);

  const useStyles = makeStyles((theme) =>
    createStyles({
      dropZone: {
        display: "flex",
        borderRadius: 16,
        border: "1px dashed black",
        padding: 16,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
      droppedZone: {
        display: "flex",
        flexDirection: "row",
        borderRadius: 16,
      },
      centerText: {
        textAlign: "center",
      },
      uploadedImage: {
        margin: 8,
        width: width,
        height: height,
        objectFit: "cover",
        background:
          "repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 16px 16px",
      },
      dimensions: {
        margin: 8,
        width: width,
        height: height,
      },
    })
  );

  const classes = useStyles();

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0];
      if (file.size > 2 * 1024 * 1024) {
        alert("File size too large");
        return;
      }

      if (file.type != "image/png" && file.type != "image/jpeg") {
        alert("Unsupported file type");
        return;
      }

      setUploadStarted(true);
      let extension = "jpg";
      if (file.type == "image/png") {
        extension = "png";
      }

      try {
        let user = await Auth.currentAuthenticatedUser();
        let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
        let dedupe = uuidv4();

        let uploadKey = `${loyaltyProgram}_${fileName}_${dedupe}.${extension}`;

        await Storage.put(uploadKey, file, {
          contentType: file.type,
          progressCallback(progress) {
            setProgress(progress.loaded / progress.total);
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
        });

        let cdnUrl = `https://d3ek2sswnxuka.cloudfront.net/${uploadKey}`;
        setUploadedFileUrl(cdnUrl);
        if (onUploadComplete) {
          onUploadComplete(cdnUrl);
        }
      } catch (err) {
        console.log(err);
        setUploadStarted(false);
      }
    }
  };

  return disabled ? (
    <Grid className={[classes.droppedZone, classes.dimensions]}>
      <img className={classes.uploadedImage} src={placeholderFileUrl} />
    </Grid>
  ) : (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <ListItem {...getRootProps()}>
          <input {...getInputProps()} />
          {uploadStarted ? (
            uploadedFileUrl ? (
              <Grid className={classes.droppedZone}>
                <img className={classes.uploadedImage} src={uploadedFileUrl} />
              </Grid>
            ) : (
              <CircularProgress variant="determinate" value={progress} />
            )
          ) : placeholderFileUrl ? (
            <Grid className={classes.droppedZone}>
              <img className={classes.uploadedImage} src={placeholderFileUrl} />
              <Grid>
                <Grid className={[classes.dropZone, classes.dimensions]}>
                  <AddCircle />
                </Grid>
                <Typography
                  className={classes.centerText}
                  variant="caption"
                  component="p"
                >
                  Click or drag to upload another image.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid className={[classes.dropZone, classes.dimensions]}>
                <AddCircle />
              </Grid>
              <Typography
                className={classes.centerText}
                variant="caption"
                component="p"
              >
                Click or drag to upload another image.
              </Typography>
            </Grid>
          )}
        </ListItem>
      )}
    </Dropzone>
  );
};

export default Uploader;
