// import Auth from "@aws-amplify/auth";
import { withAuthenticator } from "@aws-amplify/ui-react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Amplify from "aws-amplify";

import AuthenticatedApp from "./AuthenticatedApp";
import awsExports from "./aws-exports";

import "./App.css";

Amplify.configure(
  Object.assign({ ...awsExports }, { Analytics: { disabled: true } })
);

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AuthenticatedApp />
    </MuiPickersUtilsProvider>
  );
}

export default withAuthenticator(App);
