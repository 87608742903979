import Auth from "@aws-amplify/auth";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Delete, Edit, FileCopy, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { ColorPicker, createColor } from "material-ui-color";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
  })
);

function SeasonRewardItem() {
  let { season_id, sku } = useParams();
  const [rewardItem, setRewardItem] = React.useState();

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [reward, setReward] = React.useState();
  const [rewardType, setRewardType] = React.useState();
  const [rewardRuleValue, setRewardRuleValue] = React.useState("");

  const [editMode, setEditMode] = React.useState(false);

  const history = useHistory();

  const toggleEditing = () => {
    setEditMode(!editMode);
  };

  React.useEffect(() => {
    if (reward) {
      setRewardType(reward.type);

      switch (reward.type) {
        case "Recurring":
          setRewardRuleValue(reward.interval);
          break;
        case "Threshold":
          setRewardRuleValue(reward.threshold);
          break;
        case "Next":
          setRewardRuleValue(reward.minimumDelta);
          break;
      }
    }
  }, [reward]);

  React.useEffect(() => {
    const fetchRewardItem = async () => {
      let query = `query query {
          getRewardsCatalogDev(program_id: "${season_id}" sku: "${sku}") {
            terms
            sku
            reward
            program_id
            name
            internalInstructions
            imageUrl
            iconUrl
            description
            active
          }
        }`;

      let result = await API.graphql(graphqlOperation(query));
      setRewardItem(result.data.getRewardsCatalogDev);
    };
    fetchRewardItem();
  }, [sku]);

  const [terms, setTerms] = React.useState("");
  const [internalInstructions, setInternalInstructions] = React.useState("");

  const [imageUrl, setImageUrl] = React.useState("");
  const [iconUrl, setIconUrl] = React.useState("");

  React.useEffect(() => {
    if (rewardItem) {
      setName(rewardItem.name);
      setDescription(rewardItem.description);
      setReward(JSON.parse(rewardItem.reward));
      setTerms(rewardItem.terms);
      setInternalInstructions(rewardItem.internalInstructions);
      setImageUrl(rewardItem.imageUrl);
      setIconUrl(rewardItem.iconUrl);
    }
  }, [rewardItem]);

  const remove = async () => {
    try {
      let mutation = `mutation mutation {
        deleteRewardsCatalogDev(input: {
          program_id: "${season_id}",
          sku: "${sku}"}) {
            sku
        }
      }`;

      console.log(mutation);

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);

      history.push(`/seasons/${season_id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const copy = async () => {
    try {
      let rewardsJSON = {
        type: rewardType,
      };
      switch (rewardType) {
        case "Recurring":
          rewardsJSON["interval"] = rewardRuleValue;
          break;
        case "Threshold":
          rewardsJSON["threshold"] = rewardRuleValue;
          break;
      }
      let mutation = `mutation mutation {
        createRewardsCatalogDev(input: {
          program_id: "${season_id}",
          sku: "${uuidv4()}",
          active: true,
          reward: ${JSON.stringify(JSON.stringify(rewardsJSON))}
          name: "${name}",
          description: "${description}",
          iconUrl: "${iconUrl}",
          imageUrl: "${imageUrl}",
          internalInstructions: "${internalInstructions}",
          terms: "${terms}"}) {
            sku
        }
      }`;
      console.log(mutation);
      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      history.push(`/seasons/${season_id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const save = async () => {
    try {
      let rewardsJSON = {
        type: rewardType,
      };
      switch (rewardType) {
        case "Recurring":
          rewardsJSON["interval"] = rewardRuleValue;
          break;
        case "Threshold":
          rewardsJSON["threshold"] = rewardRuleValue;
          break;
      }
      let mutation = `mutation mutation {
        updateRewardsCatalogDev(input: {
          program_id: "${season_id}",
          sku: "${sku}",
          active: true,
          reward: ${JSON.stringify(JSON.stringify(rewardsJSON))}
          name: "${name}",
          description: "${description}",
          iconUrl: "${iconUrl}",
          imageUrl: "${imageUrl}",
          internalInstructions: "${internalInstructions}",
          terms: "${terms}"}) {
            sku
        }
      }`;

      console.log(mutation);

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTypeChange = (event) => {
    setRewardType(event.target.value);
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Typography variant="title" component="h1">
          Season Reward Item
        </Typography>
        {rewardItem ? (
          <List component="li">
            <ListItem>
              <ListItemText primary="Season ID" secondary={season_id} />
            </ListItem>
            <ListItem>
              <ListItemText primary="SKU" secondary={sku} />
            </ListItem>
            <ListItem>
              {editMode ? (
                <TextField
                  className={classes.textField}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  label="Reward Name"
                  helperText="Visible to customer"
                />
              ) : (
                <ListItemText primary="Reward Name" secondary={name} />
              )}
            </ListItem>
            <ListItem>
              {editMode ? (
                <TextField
                  className={classes.textField}
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                  label="Reward Description"
                  helperText="Visible to customer"
                />
              ) : (
                <ListItemText primary="Reward Name" secondary={description} />
              )}
            </ListItem>
            {editMode ? (
              <ListItem>
                <Typography component="p">Reward Type:</Typography>
                <Grid>
                  <Radio
                    checked={rewardType === "Recurring"}
                    onChange={handleTypeChange}
                    value="Recurring"
                  />
                  <Typography component="span">Recurring</Typography>
                </Grid>
                <Grid>
                  <Radio
                    checked={rewardType === "Threshold"}
                    onChange={handleTypeChange}
                    value="Threshold"
                  />
                  <Typography component="span">Threshold</Typography>
                </Grid>
              </ListItem>
            ) : (
              <ListItem>
                <Typography component="p">Reward Type: {rewardType}</Typography>
              </ListItem>
            )}
            <ListItem>
              {editMode ? (
                <TextField
                  className={classes.shortTextField}
                  value={rewardRuleValue}
                  onChange={(event) => {
                    setRewardRuleValue(Number(event.target.value));
                  }}
                  label="Value"
                />
              ) : (
                <ListItemText primary="Value" secondary={rewardRuleValue} />
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Reward Image"
                secondary="Image of the reward. 1024 x 500 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName={`${season_id}_reward_${sku}_image`}
              placeholderFileUrl={imageUrl}
              width={512}
              height={250}
              onUploadComplete={(url) => {
                setImageUrl(url);
              }}
              disabled={!editMode}
            />
            <ListItem>
              <ListItemText
                primary="Reward Icon"
                secondary="Icon of the reward. 256 x 256 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName={`${season_id}_reward_${sku}_icon`}
              placeholderFileUrl={iconUrl}
              width={48}
              height={48}
              onUploadComplete={(url) => {
                setIconUrl(url);
              }}
              disabled={!editMode}
            />
            <ListItem>
              {editMode ? (
                <TextField
                  className={classes.textField}
                  multiline
                  value={terms}
                  onChange={(event) => {
                    setTerms(event.target.value);
                  }}
                  label="Terms and Conditions"
                  helperText="Visible to customer"
                />
              ) : (
                <ListItemText
                  primary="Terms and Conditions"
                  secondary={terms}
                />
              )}
            </ListItem>
            <ListItem>
              {editMode ? (
                <TextField
                  className={classes.textField}
                  multiline
                  value={internalInstructions}
                  onChange={(event) => {
                    setInternalInstructions(event.target.value);
                  }}
                  label="Internal instructions to staff"
                  helperText="Not visible to customer"
                />
              ) : (
                <ListItemText
                  primary="Internal instructions to staff"
                  secondary={internalInstructions}
                />
              )}
            </ListItem>
          </List>
        ) : null}
      </CardContent>
      <CardActions spacing={2}>
        {editMode ? (
          <Button startIcon={<Save />} onClick={save}>
            Save Changes
          </Button>
        ) : (
          <Button startIcon={<Edit />} onClick={toggleEditing}>
            Edit
          </Button>
        )}
        <Button startIcon={<FileCopy />} onClick={copy}>
          Copy
        </Button>
        <Button startIcon={<Delete />} onClick={remove}>
          Delete Reward
        </Button>
      </CardActions>
    </Card>
  );
}

export default SeasonRewardItem;
