import Auth from "@aws-amplify/auth";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Uploader from "./Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

function OrganizationItem() {
  let { organization_id } = useParams();
  let { path, url } = useRouteMatch();
  const [loyaltyProgram, setLoyaltyProgram] = React.useState("");

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [internalInstructions, setInternalInstructions] = React.useState("");
  const [coverImageUrl, setCoverImageUrl] = React.useState("");
  const [terms, setTerms] = React.useState("");

  const [editMode, setEditMode] = React.useState(false);

  const history = useHistory();

  const toggleEditing = () => {
    setEditMode(!editMode);
  };

  React.useEffect(() => {
    const fetchOrganizationItem = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
        setLoyaltyProgram(loyaltyProgram);

        let query = `query query {
          getOrganizationDev(program_id: "${loyaltyProgram}" organization_id: "${organization_id}") {
            name
            description
            domain
            coverImageUrl
            terms
            internalInstructions
          }
        }`;

        let result = await API.graphql(graphqlOperation(query));
        let organization = result.data.getOrganizationDev;
        setName(organization.name);
        setDescription(organization.description);
        setDomain(organization.domain);
        setInternalInstructions(organization.internalInstructions);
        setCoverImageUrl(organization.coverImageUrl);
        setTerms(organization.terms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchOrganizationItem();
  }, [organization_id]);

  const remove = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        deleteOrganizationDev(input: {
          program_id: "${loyaltyProgram}",
          organization_id: "${organization_id}"}) {
            organization_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);

      history.push("/organizations");
    } catch (err) {
      console.log(err);
    }
  };

  const save = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

      let mutation = `mutation mutation {
        updateOrganizationDev(input: {
          program_id: "${loyaltyProgram}",
          organization_id: "${organization_id}",
          name: "${name}",
          description: "${description}",
          domain: "${domain}",
          terms: "${terms}",
          internalInstructions: "${internalInstructions}",
          coverImageUrl: "${coverImageUrl}"}) {
            organization_id
        }
      }`;

      console.log(mutation);

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <List component="li">
          <ListItem>
            <ListItemText
              primary="Organization ID"
              secondary={organization_id}
            />
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                label="Organization Name"
              />
            ) : (
              <ListItemText primary="Organization Name" secondary={name} />
            )}
          </ListItem>
          <ListItem>
            <ListItemText primary="Organization Logo Banner" />
          </ListItem>

          <ListItem>
            <Uploader
              fileName={`${loyaltyProgram}_${organization_id}_cover_image`}
              placeholderFileUrl={coverImageUrl}
              width={300}
              height={100}
              onUploadComplete={(url) => {
                setCoverImageUrl(url);
              }}
              disabled={!editMode}
            />
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={description}
                multiline
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                label="Benefits Description"
                helperText="Short description of benefits for member of organization"
              />
            ) : (
              <ListItemText
                primary="Benefits Description"
                secondary={description}
              />
            )}
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={domain}
                onChange={(event) => {
                  setDomain(event.target.value);
                }}
                label="Email Verification Domain"
                helperText="Member of organizations will only be verified if their email matches this domain"
              />
            ) : (
              <ListItemText
                primary="Email Verification Domain"
                secondary={domain}
              />
            )}
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={internalInstructions}
                multiline
                onChange={(event) => {
                  setInternalInstructions(event.target.value);
                }}
                label="Internal Instructions"
                helperText="Instructions for staff when processing this organization's benefits"
              />
            ) : (
              <ListItemText
                primary="Internal Instructions"
                secondary={internalInstructions}
              />
            )}
          </ListItem>
          <ListItem>
            {editMode ? (
              <TextField
                className={classes.textField}
                value={terms}
                multiline
                onChange={(event) => {
                  setTerms(event.target.value);
                }}
                label="Terms and Conditions"
                helperText="Terms and conditions of benefits shown to member of organization"
              />
            ) : (
              <ListItemText primary="Terms and Conditions" secondary={terms} />
            )}
          </ListItem>
        </List>
      </CardContent>
      <CardActions spacing={2}>
        {editMode ? (
          <Button startIcon={<Save />} onClick={save}>
            Save Changes
          </Button>
        ) : (
          <Button startIcon={<Edit />} onClick={toggleEditing}>
            Edit
          </Button>
        )}
        <Button startIcon={<Delete />} onClick={remove}>
          Delete Organization
        </Button>
      </CardActions>
    </Card>
  );
}

export default OrganizationItem;
