import Auth from "@aws-amplify/auth";
import { Card, CardContent, Container, Fab, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, TextField, Typography, Zoom } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { ColorPicker, createColor } from "material-ui-color";
import React from "react";

import Uploader from "../component/Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
  })
);

function Loyalty() {
  const classes = useStyles();

  const [programName, setProgramName] = React.useState("");
  const [programUnitName, setProgramUnitName] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState();
  const [coverImageUrl, setCoverImageUrl] = React.useState();
  const [balanceUnitImageUrl, setBalanceUnitImageUrl] = React.useState();
  const [balanceUnitContainerImageUrl, setBalanceUnitContainerImageUrl] =
    React.useState();

  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(
    createColor("#ff0000")
  );
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(
    createColor("#ff0000")
  );

  const [mainTextColor, setMainTextColor] = React.useState(
    createColor("#000000")
  );

  const [headerColor, setHeaderColor] = React.useState(createColor("#ff0000"));

  const [accrualInstructions, setAccrualInstructions] = React.useState("");
  const [creditMessage, setCreditMessage] = React.useState("");
  const [awardMessage, setAwardMessage] = React.useState("");
  const [detailsPageUrl, setDetailsPageUrl] = React.useState("");
  const [supportEmail, setSupportEmail] = React.useState("");
  const [tosUrl, setTosUrl] = React.useState("");
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = React.useState("");

  const [loyaltyProgram, setLoyaltyProgram] = React.useState();

  const [changed, setChanged] = React.useState(false);

  const save = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
      let mutation = `mutation mutation {
        updateLoyaltyProgramDev(input: {
          program_id: "${loyaltyProgram}",
          accrualInstructionsMessage: "${accrualInstructions}", 
          balanceChangedPushMessage: "${creditMessage}", 
          balanceUnitName: "${programUnitName}", 
          coverImageUrl: "${coverImageUrl}", 
          defaultDetailsPageUrl: "${detailsPageUrl}", 
          headerBarColor: "${headerColor}", 
          balanceUnitImageUrl: "${balanceUnitImageUrl}"
          balanceUnitContainerImageUrl: "${balanceUnitContainerImageUrl}"
          logoUrl: "${logoUrl}", 
          mainTextColor: "${mainTextColor}", 
          name: "${programName}", 
          privacyPolicyUrl: "${privacyPolicyUrl}", 
          rewardReceivedPushMessage: "${awardMessage}", 
          supportEmailAddress: "${supportEmail}", 
          termsOfServiceUrl: "${tosUrl}", 
          themeColorPrimary: "${primaryThemeColor}", 
          themeColorSecondary: "${secondaryThemeColor}"}) {
          program_id
        }
      }`;

      let result = await API.graphql(graphqlOperation(mutation));
      console.log(result);
      setChanged(false);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchLoyaltyProgramConfig();
  }, []);

  React.useEffect(() => {
    if (loyaltyProgram) {
      setProgramName(loyaltyProgram.name);
      setProgramUnitName(loyaltyProgram.balanceUnitName);
      setLogoUrl(loyaltyProgram.logoUrl);
      setCoverImageUrl(loyaltyProgram.coverImageUrl);
      setPrimaryThemeColor(loyaltyProgram.themeColorPrimary);
      setSecondaryThemeColor(loyaltyProgram.themeColorSecondary);
      setMainTextColor(loyaltyProgram.mainTextColor);
      setHeaderColor(loyaltyProgram.headerBarColor);
      setAccrualInstructions(loyaltyProgram.accrualInstructionsMessage);
      setBalanceUnitImageUrl(loyaltyProgram.balanceUnitImageUrl);
      setBalanceUnitContainerImageUrl(
        loyaltyProgram.balanceUnitContainerImageUrl
      );
      setCreditMessage(loyaltyProgram.balanceChangedPushMessage);
      setAwardMessage(loyaltyProgram.rewardReceivedPushMessage);
      setDetailsPageUrl(loyaltyProgram.defaultDetailsPageUrl);
      setSupportEmail(loyaltyProgram.supportEmailAddress);
      setTosUrl(loyaltyProgram.termsOfServiceUrl);
      setPrivacyPolicyUrl(loyaltyProgram.privacyPolicyUrl);
    }
  }, [loyaltyProgram]);

  const fetchLoyaltyProgramConfig = async () => {
    let user = await Auth.currentAuthenticatedUser();
    let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];

    let query = `query query {
        getLoyaltyProgramDev(program_id: "${loyaltyProgram}") {
          name
          balanceChangedPushMessage
          rewardReceivedPushMessage
          showBalance
          balanceUnitName
          balanceUnit
          balanceHorizon
          accrualInstructionsMessage
          referralBonus
          refereeBonus
          themeColorPrimary
          themeColorSecondary
          logoUrl
          termsOfServiceUrl
          privacyPolicyUrl
          coverImageUrl
          balanceUnitImageUrl
          balanceUnitContainerImageUrl
          supportEmailAddress
          season
          headerBarColor
          mainTextColor
          defaultDetailsPageUrl
        }
      }
      `;

    let result = await API.graphql(graphqlOperation(query));
    setLoyaltyProgram(result.data.getLoyaltyProgramDev);
  };

  return (
    <Container maxWidth="xl">
      <Card className={classes.root}>
        <Zoom in={changed}>
          <Fab
            className={classes.saveFab}
            variant="extended"
            color="primary"
            onClick={save}
          >
            <Save className={classes.extendedIcon} />
            Save Changes
          </Fab>
        </Zoom>
        <CardContent>
          <Typography variant="title" component="h1">
            Program Configurations
          </Typography>
          <List
            component="li"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="title" component="h3">
                  Names
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItem>
              <TextField
                className={classes.textField}
                value={programName}
                onChange={(event) => {
                  setProgramName(event.target.value);
                  setChanged(true);
                }}
                label="Loyalty Program Name"
                helperText="Name of your loyalty program, shown to your customers"
              />
            </ListItem>
            <ListItem>
              <TextField
                className={classes.textField}
                value={programUnitName}
                onChange={(event) => {
                  setProgramUnitName(event.target.value);
                  setChanged(true);
                }}
                label="Loyalty Program Units Name"
                helperText="Units of your loyalty program (eg. points, stars, bubbles, coins). Use lowercase."
              />
            </ListItem>
          </List>
          <List
            component="li"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="title" component="h3">
                  Theming
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItem>
              <ListItemText
                primary="Logo"
                secondary="Your company logo image. Image should be 512 x 512 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName="logo"
              placeholderFileUrl={logoUrl}
              width={256}
              height={256}
              onUploadComplete={(url) => {
                setLogoUrl(url);
                setChanged(true);
              }}
            />
            <ListItem>
              <ListItemText
                primary="Loyalty program cover image"
                secondary="Cover image which represents your loyalty program. Image should be 1024 x 500 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName="coverImage"
              placeholderFileUrl={coverImageUrl}
              width={512}
              height={250}
              onUploadComplete={(url) => {
                setCoverImageUrl(url);
                setChanged(true);
              }}
            />
            <ListItem>
              <ListItemText
                primary="Primary theme color"
                secondary="Primary theme color of your business, this will be used to style your app"
              />
              <ListItemSecondaryAction>
                <ColorPicker
                  value={primaryThemeColor}
                  onChange={(value) => {
                    setPrimaryThemeColor(value.css.backgroundColor);
                    setChanged(true);
                  }}
                  defaultValue="#ff0000"
                  disableAlpha
                  disablePlainColor
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Secondary theme color"
                secondary="Secondary theme color of your business, this will be used to style your app"
              />
              <ListItemSecondaryAction>
                <ColorPicker
                  value={secondaryThemeColor}
                  onChange={(value) => {
                    setSecondaryThemeColor(value.css.backgroundColor);
                    setChanged(true);
                  }}
                  defaultValue="#ff0000"
                  disableAlpha
                  disablePlainColor
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Main text color"
                secondary="Main text color of the loyalty app"
              />
              <ListItemSecondaryAction>
                <ColorPicker
                  value={mainTextColor}
                  onChange={(value) => {
                    setMainTextColor(value.css.backgroundColor);
                    setChanged(true);
                  }}
                  defaultValue="#000000"
                  disableAlpha
                  disablePlainColor
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Header bar color"
                secondary="Header bar color of the loyalty app"
              />
              <ListItemSecondaryAction>
                <ColorPicker
                  value={headerColor}
                  onChange={(value) => {
                    setHeaderColor(value.css.backgroundColor);
                    setChanged(true);
                  }}
                  defaultValue="#ff0000"
                  disableAlpha
                  disablePlainColor
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <List
            component="li"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="title" component="h3">
                  Communications
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItem>
              <TextField
                value={accrualInstructions}
                onChange={(event) => {
                  setAccrualInstructions(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Accrual Instructions"
                helperText="How do customers earn a unit of your loyalty program? (120 characters or less)"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Loyalty Unit Image"
                secondary="Image of your unit of loyalty balance (eg. stamp, coin, etc). Image should be 256 x 256 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName="balanceUnitImage"
              placeholderFileUrl={balanceUnitImageUrl}
              width={48}
              height={48}
              onUploadComplete={(url) => {
                setBalanceUnitImageUrl(url);
                setChanged(true);
              }}
            />
            <ListItem>
              <ListItemText
                primary="Loyalty Unit Container Image"
                secondary="Image of the cell that your unit of loyalty balance is in. Image should be 256 x 256 px, jpg or png."
              />
            </ListItem>
            <Uploader
              fileName="balanceUnitContainerImage"
              placeholderFileUrl={balanceUnitContainerImageUrl}
              width={48}
              height={48}
              onUploadComplete={(url) => {
                setBalanceUnitContainerImageUrl(url);
                setChanged(true);
              }}
            />
            <ListItem>
              <TextField
                value={creditMessage}
                onChange={(event) => {
                  setCreditMessage(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Loyalty balance credit message"
                helperText="Customers may see this message when they receive credit towards their loyalty balance"
              />
            </ListItem>
            <ListItem>
              <TextField
                value={awardMessage}
                onChange={(event) => {
                  setAwardMessage(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Loyalty balance award message"
                helperText="Customers may see this message when they receive a loyalty reward"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Core Details Image Url"
                secondary="Image describing the core loyalty reward program"
              />
            </ListItem>
            <Uploader
              fileName="core_details_image"
              placeholderFileUrl={detailsPageUrl}
              width={360}
              height="auto"
              onUploadComplete={(url) => {
                setDetailsPageUrl(url);
                setChanged(true);
              }}
            />
            <ListItem>
              <TextField
                value={supportEmail}
                onChange={(event) => {
                  setSupportEmail(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Support Email Address"
                helperText="Email address provided to customers if they require support"
              />
            </ListItem>
          </List>
          <List
            component="li"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="title" component="h3">
                  Legal
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItem>
              <TextField
                value={tosUrl}
                onChange={(event) => {
                  setTosUrl(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Terms of Service Page"
                helperText="Provide a link to a terms of service agreement"
              />
            </ListItem>
            <ListItem>
              <TextField
                value={privacyPolicyUrl}
                onChange={(event) => {
                  setPrivacyPolicyUrl(event.target.value);
                  setChanged(true);
                }}
                className={classes.textField}
                label="Privacy Policy Page"
                helperText="Provide a link to a privacy policy"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
}

export function Header() {
  return "Program Configuration";
}

export default Loyalty;
