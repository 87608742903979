import Auth from "@aws-amplify/auth";
import { Avatar, Card, CardContent, Container, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AddCircle, Save } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { Link, Route, BrowserRouter as Router, Switch, useParams, useRouteMatch } from "react-router-dom";

import CreateRewardItem from "../component/CreateRewardItem";
import RewardItem from "../component/RewardItem";
import Uploader from "../component/Uploader";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 275,
      margin: theme.spacing(2),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textField: {
      width: "70%",
    },
    shortTextField: {
      width: "30%",
    },
    extendedIcon: {
      marginRight: 8,
    },
    saveFab: {
      position: "fixed",
      bottom: 32,
      right: 32,
    },
    createNewItem: {
      textAlign: "center",
    },
  })
);

const save = () => {};

const RewardLineItem = ({ item }) => {
  let { path, url } = useRouteMatch();

  return (
    <Link to={`${path}/${item.sku}`} style={{ textDecoration: "none" }}>
      <ListItem button component="a">
        <ListItemAvatar>
          <Avatar src={item.iconUrl} />
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.description} />
      </ListItem>
    </Link>
  );
};

const RewardsList = () => {
  let { path, url } = useRouteMatch();

  const classes = useStyles();

  const [catalogItems, setCatalogItems] = React.useState([]);
  const [programId, setProgramId] = React.useState();

  const fetchRewardsCatalog = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let loyaltyProgram = user.attributes["custom:loyaltyProgramId"];
      setProgramId(loyaltyProgram);
      let nextToken = "";

      let query = `query query {
      listRewardsCatalogDevs(program_id: "${loyaltyProgram}", nextToken: "${nextToken}", limit: 10) {
        items {
          terms
          sku
          reward
          program_id
          name
          internalInstructions
          imageUrl
          iconUrl
          description
          active
        }
        nextToken
      }
    }  
    `;

      let result = await API.graphql(graphqlOperation(query));
      let items = result.data.listRewardsCatalogDevs.items;
      nextToken = result.data.listRewardsCatalogDevs.nextToken;

      while (nextToken != null) {
        result = await API.graphql(graphqlOperation(query));
        items = items.concat(result.data.listRewardsCatalogDevs.items);
      }
      setCatalogItems(items);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchRewardsCatalog();
  }, []);

  return (
    <Container maxWidth="xl">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="title" component="h1">
            Core Rewards
          </Typography>
          <Typography variant="title" component="h3">
            Core Rewards
          </Typography>
          <List component="li" className={classes.root}>
            {catalogItems.map((item) => (
              <RewardLineItem item={item} programId={programId} />
            ))}
            <Link to={`${path}/create`} style={{ textDecoration: "none" }}>
              <ListItem button component="a" href={`${path}/create`}>
                <ListItemText className={classes.createNewItem}>
                  <AddCircle />
                  <Typography>Create New Reward</Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

function Rewards() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path} component={RewardsList} />
        <Route path={`/rewards/create`} component={CreateRewardItem} />
        <Route path={`/rewards/:sku`} component={RewardItem} />
      </Switch>
    </Router>
  );
}

export function Header() {
  return "Loyalty Rewards";
}

export default Rewards;
