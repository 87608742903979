/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:5dfe9a45-6acc-41a0-8f4f-78f4e1ace6b7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_fErg8cEp4",
    "aws_user_pools_web_client_id": "52busovv2vln716nltvu9speqi",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "loyaltyUsers-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltyRewards-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltyBalanceTransactions-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "rewardsCatalog-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltyProgram-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "achievementCatalog-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltyAchievements-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltyReferral-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "phoneVerification-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "loyaltySeason-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "organization-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "organizationVerification-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "subscriptionPush-dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "restaurantapppublic181138-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://n6c2c5lw25aqzfwuh5zy4onvqa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "restaurantAppRestAPI",
            "endpoint": "https://4ex5yly338.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
